<template>
  <Dialog :open="!state.did_start_game">
    <div class="fixed inset-0 flex items-center justify-center p-4">
      <DialogPanel
        class="border border-white w-[800px] max-h-[90vh] flex flex-col"
      >
        <DialogTitle class="relative border-b border-white">
          <img :src="`${Config.DOM_IMG_PATH}/banner_800x150.png`" />
          <!-- <img
            :src="`${Config.DOM_IMG_PATH}/beta_stamp.webp`"
            class="absolute right-0 bottom-0 w-[200px]"
          /> -->
        </DialogTitle>

        <div class="py-6 w-full flex justify-center">
          <div
            v-if="!state.assets_loaded"
            class="w-full h-[64px] flex flex-col text-center text-xs"
          >
            Loading
            <DTSpinner class="-mt-[85px]" />
          </div>
          <!-- play button -->
          <DTButton
            v-else
            class="w-52 px-8 py-4 text-2xl"
            type="success"
            @click="onPlayBtnClicked"
          >
            {{ text('ui.PLAY') }}
          </DTButton>
        </div>

        <!-- scrollable announcements/info area -->
        <div class="p-6 pt-0 overflow-auto">
          <!-- accouncements section -->
          <div :class="classes.section_title">
            {{ text('ui.announcements') }}
          </div>

          <!-- Steam page announcement -->
          <div class="mt-6">
            <p class="font-bold text-sm">* Dungeon Team has a Steam Page!</p>
            <p class="text-xs">
              Head on over to
              <a
                href="https://store.steampowered.com/app/1509060/Dungeon_Team/"
                target="_blank"
                rel="noreferrer"
                >store.steampowered.com/app/1509060/Dungeon_Team/</a
              >
              and help get the game noticed by adding it to your wish list ...
              (& yeah it says "Coming Soon" since I'm still not ready to hit the
              big red button on that release, but shhhhh don't tell anyone they
              can just play today at dungeonteam.com) ...
            </p>
          </div>

          <!-- devlog section -->
          <div :class="classes.section_title">
            {{ text('ui.devlog') }}
          </div>
          <div class="text-xs">
            <!-- devlog: 2024.11.16 patch notes -->
            <p class="mt-6 text-sm">
              <b>2024.11.16</b> -
              <a
                href="https://wakefieldstudios.itch.io/dungeon-team/devlog/835111/patch-notes-ai-fixes-among-other-things"
                target="_blank"
                >Patch Notes</a
              >
              - AI fixes, among other things
            </p>
            <p>
              tldr; dwarf/elf/faerie and poppets summons have been limited to
              2~5, scaling with ability level
              <br />
            </p>
            <!-- devlog: 2024.11.05 patch notes -->
            <p class="mt-6 text-sm">
              <b>2024.11.05</b> -
              <a
                href="https://wakefieldstudios.itch.io/dungeon-team/devlog/829207/patch-notes-summons-limited-to-25"
                target="_blank"
                >Patch Notes</a
              >
            </p>
            <p>
              tldr; dwarf/elf/faerie and poppets summons have been limited to
              2~5, scaling with ability level
              <br />
            </p>
            <!-- devlog: DT is BACK -->
            <p class="mt-6 text-sm">
              <b>2024.11.01</b> - Dungeon Team is BACK.
            </p>
            <p>
              We're back at it, folks. It's been a crazy, crazy year, but I've
              ended up with the space to go full time at DT again. Steam release
              coming soon. Stay tuned for updates.
              <br />
            </p>
            <!-- devlog: Inn, alpha, beta -->
            <p class="mt-6 text-sm"><b>2023.04.24</b> - Dungeon Team BETA</p>
            <p>
              It's been way too long since I posted any updates here. There's A
              LOT of new stuff in the game, not least being a whole new game
              mode (the Inn)!
            </p>
            <ul class="ml-5 list-outside list-disc">
              <li>
                <p class="font-bold">
                  Inn is an async PvP mode where AI will control both yours and
                  your opponents' squads.
                </p>
                <p>
                  When you enter the Inn, you'll be assigned to a tournament
                  with up to 100 players in it. The higher your rank, the more
                  peanuts you'll gain per second. Make enough peanuts in 24
                  hours to climb to the next tier! Challenge other players to
                  steal their rank, and 10% of their peanut stash :)
                </p>
              </li>
              <li>
                <p class="font-bold">
                  Dungeon team is officially in its OPEN BETA testing phase!
                  This is the game folks!
                </p>
                <p>
                  Yes, there are still a few kinks to work out, and a couple of
                  features not quite there yet. But Dungeon Team is about
                  ongoing development, hopefully for years.
                </p>
                <p>
                  Bringing the game out to the public now is key, because it's
                  <i>YOUR</i> feedback that tells us what to work on first, and
                  what direction to take the game. Thanks for coming along on
                  this journey!
                </p>
              </li>
            </ul>
            <!-- devlog: New builds system, Inn Prototype, Caverns Working (sort of) -->
            <p class="mt-6 text-sm">
              <b>2023.02.11</b> - Wild ride, wild ride ...
            </p>
            <ul class="ml-5 list-outside list-disc">
              <li>
                So ... I tore the game apart to decouple the hero builds system
                from game modes. It's been quite a deep dive, but I gotta say
                the results are awesome. It's a whole new game, folks. More
                dungeonteamy than you ever even thought dungeonteam could be :)
              </li>
              <li>
                Inn prototype is sorta working. Still lots of bugs to fix &
                other kinks to work out but the full AI vs AI mode definitely
                adds a lot to the game.
              </li>
            </ul>
            <!-- devlog: wow this game really just won't die -->
            <p class="mt-6 text-sm">
              <b>2022.06.27</b> - Wow, this game just won't die, will it?
            </p>
            <ul class="ml-5 list-outside list-disc">
              <li>repatriated to USA</li>
              <li>created Wakefield Studios, LLC</li>
              <li>upgraded client framework from React 15 to Vue 3</li>
              <li>
                core gameplay/UX overhaul
                <ul class="ml-5 list-inside list-disc">
                  <li>24hr cycle PvP auto-battle mode (a.k.a. The Inn)</li>
                  <li>squad/equipment loadouts per game mode/submode (!!!)</li>
                  <li>monetization/shop overhaul</li>
                  <li>onboarding/tutorial overhaul</li>
                </ul>
              </li>
            </ul>
            <!-- devlog: altar update -->
            <p class="mt-6 text-sm"><b>2021.02.25</b> - Altar update!</p>
            <ul class="ml-5 list-outside list-disc text-xs">
              <li>
                fixed some bugs that were punishing players for upgrading their
                altars past level 10 (both quality & accuracy of prayers)
              </li>
              <li>
                fixed a backwards compatibility bug, where some candles/prayers
                from before the backend overhaul could break stuff
              </li>
              <li>
                adjusted the prayer item quality calculations in some ways that
                i will NEVER TELL. i will say tho, that you are no longer
                guaranteed prefixes/suffixes for every single item
              </li>
              <li>
                prayer durations now scale a lot more with altar level (to the
                cap at 10)
              </li>
              <li>
                fixed some visual bugs & layout issues on the Altar screen
              </li>
              <li>
                added mouseover/out/down/up states to all the prayer selection
                controls
              </li>
            </ul>
            <!-- devlog: 2021.02.22 -->
            <p class="mt-6 text-base"><b>2021.02.22</b></p>
            <p>
              I've been hard at work getting the game in shape for upcoming
              releases! The main thing I want to mention is:
            </p>
            <p>
              My shiny new newsletter sign-up form in the Pixie Dust Shop - go
              get another <b class="text-cyan-400">free 100 PD</b> if you
              haven't yet!
            </p>
            <p>
              Other than that, yeah a lot of code monkey stuff that probably
              wouldn't be much fun to read about, but here's a few things worth
              mentioning:
            </p>
            <div class="pb-4 pl-4 w-full">
              <div
                class="text-xs cursor-pointer text-yellow-600"
                @click="state.show_changelog = !state.show_changelog"
              >
                <div v-if="state.show_changelog">
                  <span>{{ '▲  ' }}</span
                  ><span>[{{ text('ui.hide_changelog') }}]</span>
                </div>
                <div v-else>
                  <span>{{ '▼  ' }}</span
                  ><span>[{{ text('ui.show_changelog') }}]</span>
                </div>
              </div>
            </div>
            <ul
              v-if="state.show_changelog"
              class="ml-5 list-outside list-disc"
            >
              <li>
                did a lot with connectivity / stability / fault tolerance, so
                you won't (shouldn't) have gameplay interruptions due to network
                problems or even server crashes
              </li>
              <li>fixed a bug when manually looting Caverns tiles</li>
              <li>fixed a bug saving a new deepest Caverns level</li>
              <li>
                fixed some errors you might have been seeing about missing mob
                data after Caverns travel
              </li>
              <li>repositioned some of the tutorial units</li>
              <li>
                improved minification/compression of the Javascript bundle - the
                game client should load faster
              </li>
              <li>
                fixed a bug causing tiles to become unclickable if they were
                ever obscured by a "puff of smoke" animation
              </li>
              <li>
                ☝ this and some other issues were messing up the rendering of
                caverns tiles. not sure if it's perfect now, but should be
                better.
              </li>
            </ul>
            <!-- devlog: 2021.02.06 -->
            <div class="mt-6 text-sm">
              <a
                href="https://wakefieldstudios.itch.io/dungeon-team/devlog/222541/backend-overhaul-complete-i-can-program-computers"
                target="_blank"
                rel="noreferrer"
              >
                <b>2021.02.06:</b
                ><span class="ml-3"
                  >Backend Overhaul Complete - I can program computers!</span
                >
              </a>
            </div>
            <!-- devlog: 2020.12.26 -->
            <div class="mt-6 text-sm">
              <a
                href="https://wakefieldstudios.itch.io/dungeon-team/devlog/210096/dungeon-team-lives-"
                target="_blank"
                rel="noreferrer"
              >
                <b>2020.12.26:</b
                ><span class="ml-3">Dungeon Team lives...</span>
              </a>
            </div>
          </div>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>

<script setup>
import { onBeforeUpdate, onMounted, onBeforeUnmount, reactive } from 'vue';
import { Dialog, DialogPanel, DialogTitle } from '@headlessui/vue';
import Audio from '~/Audio';
import { Config } from '~/constants';
import AccountActions from '~/flux/actions/AccountActions';
import { ApplicationStore } from '~/flux/stores';
import text from '~/text';
import { DTButton, DTSpinner } from '~/view/components/common/DOM';

const props = defineProps({
  onClose: Function,
});

const state = reactive({
  did_start_game: false,
  show_changelog: false,
  assets_loaded: false,
});

const classes = {
  section_title: 'mt-12 text-yellow-300 font-bold text-lg underline',
};

onMounted(() => {
  if (Config.PLATFORM === 'game_distribution') {
    window['GD_OPTIONS'] = {
      gameId: '38764ebe5ecc462eaf059d604e435900',
      onEvent: (event) => {
        switch (event.name) {
          case 'SDK_GAME_START':
            // advertisement done, resume game logic and unmute audio
            if (!state.did_start_game) {
              // this.startGame();
            }
            Audio.setMusicVolume(_preAdVolume);
            break;
          case 'SDK_GAME_PAUSE':
            // pause game logic / mute audio
            _preAdVolume = Audio.getMusicVolume();
            Audio.setMusicVolume(0);
            break;
          case 'SDK_GDPR_TRACKING':
            // this event is triggered when your user doesn't want to be tracked
            break;
          case 'SDK_GDPR_TARGETING':
            // this event is triggered when your user doesn't want personalised targeting of ads and such
            break;
        }
      },
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://html5.api.gamedistribution.com/main.min.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'gamedistribution-jssdk');
  } else if (Config.PLATFORM === 'armor_games') {
    document.domain = 'armorgames.com';
    (function () {
      var ag = null;
      document.addEventListener('DOMContentLoaded', function (event) {
        var agiChecks = 0;
        function checkForAGI() {
          if (agiChecks > 1000) return;

          try {
            if (typeof parent.parent.agi !== 'undefined') {
              ag = new ArmorGames({
                user_id: parent.parent.apiAuth.user_id,
                auth_token: parent.parent.apiAuth.auth_token,
                game_id: parent.parent.apiAuth.game_id,
                api_key: '0E8060CF-72E7-472D-90D2-8CEF069A2844',
                agi: parent.parent.agi,
              });

              // ... you can start doing AG requests
            } else {
              agiChecks++;
              window.setTimeout(checkForAGI, 250);
            }
          } catch (err) {
            agiChecks++;
            window.setTimeout(checkForAGI, 250);
          }
        }
        checkForAGI();
      });
    })();
  }

  ApplicationStore.on(ApplicationStore.ASSETS_LOADED, onAssetsLoaded);
});

onBeforeUnmount(() => {
  ApplicationStore.removeListener(
    ApplicationStore.ASSETS_LOADED,
    onAssetsLoaded
  );
});

function onAssetsLoaded() {
  state.assets_loaded = true;
}

function onPlayBtnClicked() {
  if (Config.PLATFORM === 'game_distribution') {
    if (
      typeof window.gdsdk !== 'undefined' &&
      window.gdsdk.showAd !== 'undefined'
    ) {
      state.is_open = false;
      window.gdsdk.showAd();
    }
  } else {
    // start game
    AccountActions.authenticate();
    state.did_start_game = true;
    props.onClose();
  }
}
</script>

<style>
.play-btn-wrapper {
  text-align: center;
  margin-bottom: 10px;
}

.play-btn,
.play-btn:focus {
  color: green;
  padding: 5px 30px 5px 30px;
  font-size: 32px;
  font-weight: bold;
}

.welcome-section > p {
  font-size: 12px;
}

.video-wrapper {
  text-align: center;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 0px;
  height: 0;
}
.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.submit-video {
  margin-top: 5px;
  font-size: 10px;
  text-align: right;
}

.patch-notes {
  font-size: 12px;
}

.help-lost-game-data {
  font-size: 10px;
  text-align: right;
}

.help-lost-game-data > a {
  color: red;
}

.changelog-ul,
.changelog-ul > li > ul {
  padding-left: 17px;
}
</style>
